

#about{
    width: 100%;
    background: linear-gradient(243.01deg, #82f1af 33.13%, #2FCB6E 125.77%);
    padding: 1rem;
}
.about_row{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.about_left_col{
    width: 70%;
    padding: 2rem;
}

.about_right_col{
    width: 30%;
    text-align: center;
    padding: 2rem;
}
.about_right_col img{
    width:100%;
    height: auto;
    filter: drop-shadow(2px 19px 18px rgba(0, 0, 0, 0.25));
   
}


.about_left_col h3{
    font-size: 3rem;
    color: black;
}
.about_left_col p{
    font-size: 1.3rem;
    text-align: justify;
    text-transform: capitalize;
    margin: 2rem 0px;
    color: black;
    width: 80%;
}
.c_btn{
    font-size: 1rem;
    padding: 10px 20px;
    margin: 10px 0px;
    color: #fff;
    border: none;
    background: #1D1E23;
    border-radius: 44.24px;
}
.c_btn:hover{
    color: #2FCB6E;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
@media (max-width: 600px){
    .about_row{
        display: block;
    }
    .about_left_col{
        width: 100%;
        padding: 0;
        margin-bottom: 2rem;
    }
    .about_left_col p{
        text-transform: capitalize;
        margin: 2rem 0;
        width: 100%;
    }
    .about_right_col{
        width: 100%;
        padding: 0;
    }
    .about_left_col h3{
        font-size: 2rem;
    }
}