.container-fluid .hero-row{
    height: 100vh;
}
.container-fluid .hero-right{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-fluid .hero-right img{
      width: 100%;
      object-fit: cover;
  }
  .container-fluid .hero-left{
    z-index: 1;
  }
  .container-fluid .hero-left h1{
      background-image: var(--primary-buttom);
      background-clip: text;
      color: transparent;
      font-style: normal;
      font-weight: 800;
      font-size: 9vmin;
      margin-left: 20px;
  }
  .container-fluid .hero-left h3{
    /* color: var(--font-color); */
    color: white;
    margin-left: 20px;
    font-size: 3.5vmin;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .hero-left p{
    margin-left: 20px;
    width: 90%;
  }
  .container-fluid .hero-left button{
    margin-left: 20px;
    font-size: 3vmin;
  }
  .vector-01{
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .vector-01 svg{
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 1200px) {
    .sm-hero{
      display: none;
    }
  }
  @media only screen and (max-width: 770px) {
    *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    #home{
      margin: 0;
      padding: 0;
    }
    .desktop-hero{
      display: none;
    }
    .sm-hero{
      padding: 0;
    }
    .sm-row-hero{
      height: 100vh;
      width: 100%;
      margin: 0;
      padding: 0;
      padding-top: 8vh;
      text-align: center;
      border-radius: 3rem 0rem 2rem 2rem;
    }
    .mobile-hero{
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.788), rgba(0, 0, 0, 0.11)), url('../../asset/mobile-hero.png');
      background-repeat: no-repeat;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      border-radius: 10% 10% 41% 44% / 0% 0% 25% 26% ;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50%;
    }

    .mobile-hero img{
        width: 95vw;
        position: relative;
        bottom: 0;
        transform: translateY(115px);
        
    }
    .sks-btn{
      font-size: 0.8rem;
    }
    .lower-hero{
      /* border: 2px solid red; */
      height: 50%;
      margin-top: 50px;
    }
    .child_one{
      background-color: #2d2e32;
      padding-bottom: 0.5rem;
      border-radius: 1rem;
      margin-top: 1rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }

    .child_one img{
      width: 100%;
      height: auto;
      border-radius: 0.7rem 0.7rem 0rem 0rem;
    }
    .child_one p{
      text-align: center;
      line-height: 18px;
      font-size: 0.8rem;
      padding: 0.2rem 0.2rem 0rem 0.2rem;
    }

    .child_two{
      background-color: #2d2e32;
      padding-bottom: 0.5rem;
      
      margin-top: 1rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    .child_two img{
      width: 100%;
      height: auto;
    }
    .hero_card{
      padding: 0.2rem;
    }
    .title{
      padding: 0rem 0.5rem 0rem 0.5rem;
      color: var(--font-color);
      margin: 1rem 0rem 1rem 0rem;
    }
    .title h5{
      border-left: 0.5rem solid var(--secondery-color);
      padding-left: 0.5rem;
    }
    .title p{
        font-size: 0.7rem;
    }

  }