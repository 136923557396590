.register_container{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
.form_box_register{
    width: 80%;
    height: 80%;
    background: rgb(63, 65, 68);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_box_register .left{
    height: 100%;
    width: 50%;

}
.form_box_register .left img{
    height: 100%;
    width: auto;
    border-radius: 5px 0px 0px 5px;
}
.form_box_register .right{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.form_box_register input {
    padding: 0.4rem;
    border-radius: 1rem;
    background-color: aliceblue;
    width: 50%;
    color: black;
    border: none;
    padding-left: 1rem;
    font-size: 0.8rem;
}
.form_box_register button{
    padding-left: 1rem;
    font-size: 0.8rem;
}
.form_box_register h1{
    font-size: 1.8rem;
}
.form_box_register p{
    width: 70%;
    margin: 0px 0px 15px 0px;
    font-size: 0.8rem;
}