.login_content{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login_content .left{
    width: 70%;
    height: 100%;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.login_content .right{
    width: 30%;
    height: 100%;
    background: var(--primary-buttom);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 2rem;
}
.form_box{
    width: 40%;
    padding: 1rem;
}
.form_box button{
    width: 50%;
    padding: 0.7rem;
    border-radius: 1.5rem;
}
.form_box input{
    color: var(--primary-color);
    padding: 0.7rem;
    border-radius: 1.5rem;
    padding-left: 1rem;
}
.login_content .right p{
    color: black;
}